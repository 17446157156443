/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { Link } from "react-router-dom";
import { Nav } from "./styles";
import logoImg from "../../../assets/images/site/logo-easy-2023.png";

export default function Header() {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const handleClick = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  function renderLogo() {
    if (window.location.pathname === "/") {
      return (
        <button
          type="button"
          onClick={scrollToTop}
          style={{ border: "none", background: "transparent" }}
        >
          <img src={logoImg} alt="Logo EasyDr." width={150} />
        </button>
      );
    }

    return (
      <Link to="/">
        <img src={logoImg} alt="Logo EasyDr." width={70} />
      </Link>
    );
  }

  return (
    <Nav>
      <ul className={`sidebar-lp ${isSidebarVisible ? "visible" : ""}`}>
        <li>
          <button type="button" onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="26px"
              viewBox="0 -960 960 960"
              width="26px"
              fill="currentColor"
            >
              <path d="m256-200-56-56 224-224-224-224 56-56 224 224 224-224 56 56-224 224 224 224-56 56-224-224-224 224Z" />
            </svg>
            Fechar
          </button>
        </li>
        {/* <li>
          <a href="https://marketing.easydr.com.br/simulador-de-impostos-easy-dr">
            Quem Somos
          </a>
        </li> */}
        <li>
          <a href="https://marketing.easydr.com.br/simulador-de-impostos-easy-dr">
            Simulador de Impostos
          </a>
        </li>
        <li>
          <a href="https://wa.me/553299681775?text=Estou%20vindo%20pelo%20site%20EasyDr.">
            Quero Ser Cliente
          </a>
        </li>
        <li>
          <a href="https://vip.acessorias.com/easydrcontabilidade">
            Já Sou Cliente
          </a>
        </li>
        <li>
          <a href="#trabalhe-conosco" onClick={handleClick}>
            Trabalhe Conosco
          </a>
        </li>
        <li>
          <a
            onClick={handleClick}
            target="_blank"
            href="https://blog.easydr.com.br/"
            rel="noreferrer"
          >
            Blog
          </a>
        </li>
        <></>
      </ul>
      <ul className="menu-desktop">
        <li>{renderLogo()}</li>
        {/* <li className="hideOnMobile">
          <a href="https://marketing.easydr.com.br/simulador-de-impostos-easy-dr">
            Quem Somos
          </a>
        </li> */}
        <li className="hideOnMobile">
          <a href="https://marketing.easydr.com.br/simulador-de-impostos-easy-dr">
            Simulador de Impostos
          </a>
        </li>
        <li className="hideOnMobile">
          <a href="https://wa.me/553299681775?text=Estou%20vindo%20pelo%20site%20EasyDr.">
            Quero Ser Cliente
          </a>
        </li>
        <li className="hideOnMobile">
          <a href="https://vip.acessorias.com/easydrcontabilidade">
            Já Sou Cliente
          </a>
        </li>
        <li className="hideOnMobile">
          <a href="#trabalhe-conosco">Trabalhe Conosco</a>
        </li>
        <li className="hideOnMobile">
          <a
            target="_blank"
            href="https://blog.easydr.com.br/"
            rel="noreferrer"
          >
            Blog
          </a>
        </li>
        <li className="menu-button">
          <button type="button" onClick={handleClick}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="26"
              viewBox="0 -960 960 960"
              width="26"
              fill="#025dfd"
            >
              <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
            </svg>
          </button>
        </li>
      </ul>
    </Nav>
  );
}
