import { api } from "../services/api";



async function login({ email, senha }) {
  try {
    const response = await api.post("/api/token", {
      username: email,
      password: senha
    });
    const {
      access,
      refresh,
      first_name,
      last_name,
      user_id,
      user_profiles,
      is_admin,
    } = response.data;

    return {
      access,
      refresh,
      email: response?.data?.email,
      first_name,
      last_name,
      user_id,
      user_profiles,
      is_admin,
    }
  } catch (e) {
    console.log("Erro no login");
    console.log(e);
    return {
      status: false,
      token: "",
      error: "Error",
    };
  }
}

export default {
  login,
};
