async function GetBase64(file) {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

}

function downloadOFXFile(fileUrl, fileName) {
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

function getFirstAndLastDayOfMonth() {
    const today = new Date();
    const first = new Date(today.getFullYear(), today.getMonth(), 1);
    const last = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return {
        first,
        last
    };
}



function formatDate(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Remove horas, minutos e segundos para comparar apenas o dia
    const isToday = inputDate.toDateString() === currentDate.toDateString();

    if (isToday) {
        // Retorna apenas a hora no formato HH:mm
        return inputDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });
    } else {
        // Retorna o dia e o mês no formato DD/MM
        return inputDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit' });
    }
}

function formatCpfOrCnpj(input) {
    try {
        // Remove todos os caracteres que não sejam números
        const numericInput = input.replace(/\D/g, '');

        if (numericInput.length === 11) {
            // Formata como CPF: XXX.XXX.XXX-XX
            return numericInput.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else if (numericInput.length === 14) {
            // Formata como CNPJ: XX.XXX.XXX/XXXX-XX
            return numericInput.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        // Retorna o valor original se não for CPF nem CNPJ
        return input;
    } catch (e) {
        return input
    }
}




export default {
    GetBase64, getFirstAndLastDayOfMonth,
    downloadOFXFile,
    formatDate,
    formatCpfOrCnpj
}