import { api } from "../services/api";

async function list() {
    try {
        const { data } = await api.get("/api-operacional/last-messages", {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("EASY-TOKEN")}`
            }
        });
        console.log(data)
        return data;
    }
    catch (e) {
        console.log(e)
        return []
    }


}

async function getMessages(id) {
    try {
        const { data } = await api.get("/api-operacional/messages/user/" + id, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("EASY-TOKEN")}`
            }
        });
        console.log(data)
        return data;
    }
    catch (e) {
        console.log(e)
        return []
    }

}
async function sendMessage(to_user, msg) {
    try {
        const { data } = await api.post("/api-operacional/messages/user", {
            content: msg,
            to_user
        }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("EASY-TOKEN")}`
            }
        });
        console.log(data)
        return true;
    }
    catch (e) {
        console.log(e)
        return false
    }
}


export default {
    list, getMessages, sendMessage
};
