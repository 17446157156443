import {api} from "../services/api";

async function upload( type, payload) {
    try {
        const { data } = await api.post("/blob-storage/createJson", { type, payload });
        return data;
    }
    catch (e) {

        return {
            status: false,
            token: "",
            error: "Error"
        }
    }

}


export default {
    upload,
};
