import { Redirect, Route, Switch } from "react-router-dom";

import { Row } from "react-bootstrap";
import Home from "../pages/Home";
import Login from "../pages/Sistema/Login";
import Error from "../pages/Error";
import { Consultoria } from "../pages/Consultoria";
import { Simulador } from "../pages/Simulador";
import UploadBi from "../pages/Sistema/Bi";
import Dashboard from "../pages/Sistema/Dashboard";
import AuthService from "../services/auth";
import Robots from "../pages/Robots";
import CarteiraColaborador from "../pages/Sistema/Robots/CarteiraColaborador";
import Parametrizacao from "../pages/Sistema/Parametrizacao";
import Conversor from "../pages/Sistema/Conversor";
import RobotEcac from "../pages/Sistema/RobotEcac";
import GuiasEacColaborador from "../pages/Sistema/GuiasEcacColaborador";
import ConsultaReceita from "../pages/Sistema/ConsultaReceita";
import SituacaoFiscal from "../pages/Sistema/Robots/SituacaoFiscal";
import Sidebar from "../components/SideBar";
import GuiaDas from "../pages/Sistema/Robots/GuiaDas";
import EmissaoNotaNew from "../pages/Sistema/Nfe/EmissaoNotaNew";
import ListNfes from "../pages/Sistema/Nfe/ListNfe";
import Marketing from "../pages/Sistema/Marketing/Marketing";
import { PaginaDeVagas } from "../pages/Vaga";
import BancoDeTalentos from "../pages/Sistema/Rh";
import CorretorOfx from "../pages/Sistema/CorretorOfx/index";
import NfeLote from "../pages/Sistema/Nfe/NfeLote";
import ListarInventario from "../pages/Sistema/Inventario/listar";
import Usuarios from "../pages/Sistema/Usuarios";
import EditarUsuario from "../pages/Sistema/EditarUsuario/index";
import { politicaApp } from "../pages/PoliticaApp";
import ChatApp from "../pages/ChatApp";

export default function Routes() {
  let user = null;
  try {
    const userStr = localStorage.getItem("EASY-USER");
    if (userStr) {
      user = JSON.parse(userStr);
    }
  } catch (e) {
    console.log("New Session");
  }

  // Verifica se user é válido e se roles é um array antes de usar .some
  const isMarketing =
    Array.isArray(user?.roles) &&
    user.roles.some((role) => role.name === "Marketing");
  const isRh =
    Array.isArray(user?.roles) && user.roles.some((role) => role.name === "Rh");
  const PrivateRoute = ({ path, component }) => {
    const authServ = new AuthService();
    if (authServ.isAuthenticated()) {
      if (isMarketing) {
        return (
          <Row style={{ justifyContent: "flex-end" }}>
            {/* <div className="div-sidebar-app">
              <Sidebar isMarketing />
            </div> */}

            <div className="div-route-main">
              <Route path={path} exact component={Marketing} />
            </div>
          </Row>
        );
      }
      return (
        <Row style={{ justifyContent: "flex-end" }}>
          {/* <div className="div-sidebar-app">
            <Sidebar />
          </div> */}

          {/* <div className="div-route-main"> */}
          <Route path={path} exact component={component} />
          {/* </div> */}
        </Row>
      );
    }

    window.location = "/app/login";
    return null;
  };

  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/simular" exact component={Simulador} />
      <Route path="/consultoria-gravada" exact component={Consultoria} />
      <Route path="/vaga/:slug" component={PaginaDeVagas} />
      <Route path="/app/login" component={Login} />
      <Route path="/politica-app" component={politicaApp} />
      <PrivateRoute path="/app/dashboard" component={Dashboard} />

      <PrivateRoute path="/app/chat" component={ChatApp} />

      <PrivateRoute path="/app/marketing" component={Marketing} />

      <PrivateRoute path="/app/nf-e/emitir" component={EmissaoNotaNew} />
      <PrivateRoute path="/app/nf-e/list" component={ListNfes} />

      <PrivateRoute path="/app/parametrizacao-lp" component={Parametrizacao} />
      <PrivateRoute path="/app/bi/fonte-dados-bi" component={UploadBi} />
      <PrivateRoute path="/app/corretor-ofx" component={CorretorOfx} />

      <PrivateRoute path="/app/robots" component={Robots} />
      <PrivateRoute
        path="/app/situacao-fiscal-ecac"
        component={SituacaoFiscal}
      />

      <PrivateRoute path="/app/robot-ecac" component={RobotEcac} />

      <PrivateRoute path="/app/colab-wallet" component={CarteiraColaborador} />
      <PrivateRoute path="/app/conversor" component={Conversor} />

      <PrivateRoute path="/app/tools/guia-das" component={GuiaDas} />

      {isRh && (
        <PrivateRoute
          path="/app/rh/banco-de-talentos"
          component={BancoDeTalentos}
        />
      )}

      <PrivateRoute
        path="/app/tools/guias-ecac-colaborador"
        component={GuiasEacColaborador}
      />

      <PrivateRoute path="/app/tools/nfe-lote" component={NfeLote} />

      <PrivateRoute
        path="/app/tools/consulta-receita"
        component={ConsultaReceita}
      />

      {/* Inventário */}
      <PrivateRoute
        path="/app/inventario/listar"
        component={ListarInventario}
      />

      <PrivateRoute path="/app/usuarios/listar" component={Usuarios} />

      <PrivateRoute
        path="/app/usuarios/cadastrar"
        component={ConsultaReceita}
      />

      <PrivateRoute path="/app/usuarios/editar/:id" component={EditarUsuario} />

      <Route path="/404" component={Error} />
      <Redirect to="/" />
    </Switch>
  );
}
