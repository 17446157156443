import React from "react";

import Button from "../../Global/Form/Button";
import FotoNathan from "../../../assets/images/site/foto-nathan.webp";

import {
  BannerHero,
  Container,
  Content,
  Title,
  Description,
  BannerImage,
  ButtonRow,
  Image,
} from "./styles";
import Switch from "./Switch";

export default function Banner() {
  function handleToRegister() {
    window.open(
      "https://wa.me/553299681775?text=Estou%20vindo%20pelo%20site%20EasyDr.",
      "_blank"
    );
  }
  return (
    <BannerHero>
      <Container>
        <Content>
          <Title>Ganhe mais tempo para sua carreira médica</Title>
          <Switch />
          <Description>
            <b>Aumente seu faturamento em até 20%</b> através de um planejamento
            tributário com quem é especialista no assunto
          </Description>
          <ButtonRow>
            <Button
              width={100}
              primary
              color="#025DFD"
              type="submit"
              onClick={() => handleToRegister()}
            >
              Quero Ser Cliente
            </Button>
          </ButtonRow>
        </Content>

        <BannerImage>
          <Image
            src={FotoNathan}
            alt="Nathan Vasconcellos, Economista, Contador, Mestre em Finanças e Professor Universitário."
          />
        </BannerImage>
      </Container>
    </BannerHero>
  );
}
