import axios from "axios";

const BACKEND_URL = "https://rpc.easydr.com.br"; // URL base da API do Django.
const api = axios.create({
  baseURL: BACKEND_URL,
});

// Utilitários para manipular tokens no localStorage
const getAccessToken = () => localStorage.getItem("EASY-TOKEN");
const getRefreshToken = () => localStorage.getItem("refresh");
const setAccessToken = (token) => localStorage.setItem("EASY-TOKEN", token);
const removeTokens = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
};

// Configura interceptors do Axios
const configureAxios = () => {

  // Interceptor para adicionar o token de acesso nos cabeçalhos
  api.interceptors.request.use(
    (config) => {
      const accessToken = getAccessToken();
      console.log("accessToken", accessToken);
      if (accessToken) {
        config.headers.Authorization = `${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Interceptor para gerenciar erros de resposta
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = getRefreshToken();

        if (refreshToken) {
          try {
            const response = await axios.post(`${BACKEND_URL}token/refresh/`, {
              refresh: refreshToken,
            });

            const newAccessToken = response.data.access;
            setAccessToken(newAccessToken); // Atualiza o accessToken no localStorage.
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            return api(originalRequest); // Rechama a API com o novo token.
          } catch (refreshError) {
            // Falha ao atualizar o token
            removeTokens(); // Remove tokens do localStorage.
            window.location.href = '/login'; // Redireciona para a página de login.
          }
        } else {
          // Se não houver refreshToken, redireciona para login
          removeTokens();
          window.location.href = '/login';
        }
      }

      return Promise.reject(error);
    }
  );
};

export { api, configureAxios };
