/* eslint-disable class-methods-use-this */
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";

const API_TOKEN_NAME = "EASY-TOKEN";
const API_USER_DATA = "EASY-USER";

class AuthService {
  getTokenDecode() {
    const decodedToken = jwt_decode(localStorage.getItem(API_TOKEN_NAME));
    return decodedToken;
  }

  getUser() {
    const { user } = this.getTokenDecode();
    if (!user) {
      return this.signinRedirect();
    }
    return user;
  }

  getUserId() {
    const { user } = this.getUser();
    return user.id;
  }

  getCompanyId() {
    const { companyId } = this.getTokenDecode();
    return companyId;
  }

  login(res) {
    localStorage.setItem(API_TOKEN_NAME, res.access);
    localStorage.setItem(API_USER_DATA, JSON.stringify(res));
    return true;
  }

  getToken() {
    try {
      const token = localStorage.getItem(API_TOKEN_NAME);
      if (!token || token === "undefined") return null;
      return token;
    } catch {
      return null;
    }
  }

  isAuthenticated() {
    const token = localStorage.getItem(API_TOKEN_NAME);

    if (!token) return false;

    try {
      const { exp: expiration } = this.getTokenDecode();
      const isExpired = !!expiration && Date.now() > expiration * 1000;

      if (isExpired) return false;
      return true;
    } catch (e) {
      return false;
    }
  }

  logout() {
    localStorage.removeItem(API_USER_DATA);
    localStorage.removeItem(API_TOKEN_NAME);
    this.signinRedirect();
  }
}

export default AuthService;
