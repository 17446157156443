import React, { useState, useEffect, useRef } from "react";
import { IoSendSharp, } from "react-icons/io5";
import "../../assets/css/chatbot.css"
import chatModel from "../../models/chat.model";
import { Spinner } from "react-bootstrap";
import utils from "../../helpers/utils";

const Chatbot = () => {

  const [messages, setMessages] = useState([]);
  const [userList, setUserList] = useState([])
  const [userMessage, setUserMessage] = useState("");

  const [loadMessages, setLoadMessages] = useState(false)
  const [loadList, setLoadList] = useState(false);
  const [activeChat, setActiveChat] = useState(null)

  const [search, setSearch] = useState("")


  const divRef = useRef(null);


  const scrollToBottomMessages = () => {

    if (divRef?.current) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
      setTimeout(() => {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }, 200);
      setTimeout(() => {
        divRef.current.scrollTop = divRef.current.scrollHeight;
      }, 300);
    }
  }

  const findList = async () => {
    const res = await chatModel.list()
    setUserList(res)
    return res
  }
  const init = async () => {
    setLoadList(true)
    const res = await findList()
    setLoadList(false);

    if (res.length > 0) {
      setActiveChat(res[0])
    }
  }

  const findChat = async () => {
    const msgs = await chatModel.getMessages(activeChat.from_user_id)
    setMessages(msgs)

  }
  const getChat = async () => {
    setLoadMessages(true)
    if (activeChat?.from_user_id) {
      await findChat()
    }
    setLoadMessages(false)
  }


  const sendMessage = async (msg) => {
    setMessages([
      ...messages,
      {
        content: msg,
        role: "user",
        date_created: new Date().toISOString()
      }
    ])
    setUserMessage("")


    scrollToBottomMessages()

    const msgs = await chatModel.sendMessage(activeChat.from_user_id, msg)
    await findChat()
    await findList()
  };

  useEffect(() => {
    init();
  }, [])

  useEffect(() => {
    if (activeChat) {
      getChat()
      scrollToBottomMessages()
    }
  }, [activeChat])

  useEffect(() => {

    const intervalId = setInterval(async () => {
      await findList()
      await getChat()
    }, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);



  return (
    <div className="chat-container">
      <div className="chat-sidebar">
        <div className="chatbot-header"  >
          <h5 style={{ userSelect: "none" }}>Atendimentos</h5>
          <span style={{
            userSelect: "none",
            fontSize: 12, fontStyle: "italic",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}>Horário de Atendimento das 9h ás 18h</span>
        </div>
        <div className="chat-user-box">
          <div className="p-2">
            <input
              type="text"
              className="form-control"
              placeholder="Buscar Empresa..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          {loadList && <div className="mt-4 mb-4">
            <center>
              <Spinner />
            </center>
          </div>}
          {userList?.filter((a) => {
            const nomeCompleto = a.user_profile?.nome_completo?.toLowerCase() || '';
            const cpfCnpj = a.user_profile?.cpf_cnpj?.toLowerCase() || '';
            const searchTerm = search?.toLowerCase() || '';
            return nomeCompleto.includes(searchTerm) || cpfCnpj.includes(searchTerm);
          })
            .sort((a, b) => a.user_profile?.nome_completo.localeCompare(b.user_profile?.nome_completo))
            .map((e, index) => (

              <div className={"chat-user-mss" + (e?.id === activeChat?.id ? " mss-selected" : "")} onClick={() => setActiveChat(e)}>
                <div style={{ display: 'flex', gap: 10, }} onClick={() => setActiveChat(e)}>
                  <img src="https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg" className="chat-user-image" />
                  <div style={{ display: 'grid' }}>
                    <span>{e.user_profile?.nome_completo ?? e.user}</span>
                    <span className="chatbot-header-cnpj" style={{ fontSize: 10 }}>{e.content}</span>
                  </div>
                </div>
                <div>
                  <span>
                    {utils.formatDate(e.date_created)}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="chatbot-container">
        <div className="chatbot-header" style={{
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          display: "flex", gap: 10
        }}>
          {activeChat && <img src="https://www.htgtrading.co.uk/wp-content/uploads/2016/03/no-user-image-square.jpg" className="chat-user-image" />}
          <div style={{ display: 'grid' }}>
            <span style={{ fontSize: 24 }}>{activeChat?.user_profile?.nome_completo}</span>
            {activeChat && <span className="chatbot-header-cnpj">{utils.formatCpfOrCnpj(activeChat?.user_profile?.cpf_cnpj)} ({activeChat?.user_profile?.tipo_de_tributacao})</span>}
          </div>
        </div>
        <div className="chatbot-wrapper">
          <div className="chatbot-messages" ref={divRef}
          >
            {loadMessages && <div className="mt-4 mb-4">
              <center>
                <Spinner />
              </center>
            </div>}
            {messages.map((msg, idx) => (
              <div
                key={idx}
                className={`chat-message ${msg.role === "user" ? "chat-user" : "chat-system"
                  }`}
              >
                <div className="chat-bubble">
                  <span  >{msg.content}</span>
                  {msg.buttons?.length > 0 && (
                    <div className="chat-buttons">
                      {msg.buttons.map((btn, i) => (
                        <button
                          key={i}
                          onClick={() => sendMessage(btn)}
                          className="chat-button"
                        >
                          {btn}
                        </button>
                      ))}
                    </div>
                  )}
                  <span className="chat-timestamp" style={{ color: msg.role !== "user" ? "#1d4ed8" : "" }}>{utils.formatDate(msg.date_created)}</span>
                </div>
              </div>
            ))}
          </div>

          <div className="chatbot-input">
            <input
              type="text"
              placeholder="Digite sua mensagem..."
              value={userMessage}
              onChange={(e) => setUserMessage(e.target.value)}
              onKeyPress={(e) =>
                e.key === "Enter" && sendMessage(userMessage)
              }
              className="chat-input-field"
            />
            <button
              onClick={() => sendMessage(userMessage)}
              className="chat-send-btn"

            >
              <IoSendSharp size={24} style={{ marginLeft: 5 }} />
            </button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Chatbot;
